import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { orderListFleetId, orderListFleetIdtwo } from '../ApI/Index'
import { orderlist } from '../Data/Orderlist'
import CancelOrder from './CancelOrder'
import OpenCabin from './OpenCabin'
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react'

function OrdersTable() {

  const [ordertable,setordertable] =useState(false)
  const [orderList, setorderlist]= useState()
  const [call, setcall]=useState(false)
  var fleetId = localStorage.getItem('fleetId')
  const size="10";
  const [Paginationlength, setPaginationlength] =useState()
  const token = localStorage.getItem('token')
  useEffect(()=>{
    setordertable(true)

    setInterval(()=>{
       orderListFleetId(fleetId ,page, size, token).then((res)=>{
        setorderlist(res.data)
          console.log('OrderList Response',res)  
       })
       .catch(err=>{
        console.log(err)
       })
    
    },7000)

 
  },[ordertable])
 
  const orderDataRedux = useSelector(state=>state.OrderAction)
  const getStatusClass=(status)=>{
    switch(status){
      case "enroute to pickup": 
        return null
      case "order delivered":  
        return 'p-color-green'
      case "order cancelled":
        return  'p-color-red'
      case "waiting for pickup":  
        return 'color-blue'
     }
 }


const getStatusButton=(item)=>{
 switch(item.statusCode){
      case 1:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 2:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 3:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 4:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 5:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 6:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 7:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/>    
      case 8:
        return  <CancelOrder data={orderList} id={item.orderId} fleetid={fleetId}/> 
      case 9:  
       return  <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Cancel</Button>
      case 10:
       return  <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Cancel</Button>
    
    }
} 
const getOpenbutton =(item)=>{
 switch(item.statusCode){
      case 1:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 2:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 3:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 5:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 6:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 7:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 9:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 10:  
       return <Button disabled={true} style={{opacity:'0.5'}} className="Dashboard-table-action-btn"> Open Cabin</Button>  
       case 4:  
        return <OpenCabin data={orderList} id={item.orderId} fleetid={fleetId}/>
       case 8:  
       return <Button disabled={true} className="Dashboard-table-action-btn"> Open Cabin</Button>  
    
    
    }
}

const getDeliverycolor=(status)=>{
  switch(status){
    case "enroute to pickup":
      return 'drop'
    case "order delivered":  
      return 'drop delivered'
    case "order cancelled":
      return 'drop cancel'
    case " waiting to pickup the order":  
      return 'drop dropoff'
   }
}

const [page, setPage] = React.useState(1);
  const handleChange = useCallback((event, value) => {
    setPage(value);
    orderListFleetId(fleetId ,page, size).then((res)=>{
      setorderlist(res.data)
        console.log('OrderList Response',res) 
        if(res.data.data.order.length>0){
          // setPaginationlength(Paginationlength => Paginationlength+1)
        } 
     })
     .catch(err=>{
      console.log(err)
     })
  },[orderList]) 
  return (
    <>
    {/* {console.log('orderDataRedux',orderDataRedux)} */}
<Stack spacing={2}>
  <Typography>
    <table className="OrderList-table-wrapper-Main">
      <thead>
        <tr> 
          <th style={{width:'27%'}}>Order ID</th>
          <th>PickUp/Delivery Location</th>
          <th>Start and End Time</th>
          <th>Items</th>
          <th>Status</th>
          <th style={{textAlign:"end" }}>Action</th>
        </tr> 
      </thead>
      <tbody> 
        {/* {console.log('page',page,orderDataRedux?.orderData?.length)} */}

       {/* {orderDataRedux?.orderData?.length<=0 || orderDataRedux?.orderData?.length==undefined ?  */}
       {
        console.log(orderList,'Order List')
       }
       <> {orderList?.data?.order?.map((item)=>{
          return(
            <tr>
              <td> 
               <h5 className='color-blue'>{item.orderId}</h5>
               <p> {item.robotId}</p>
             </td>
            <td> 
            <div class='curve-bg'>
                <p className='pick'> {item.pickupId}</p> <br />
                <p className={getDeliverycolor(item.orderStatus)}>{item.dropId} </p>  
            </div>
              {/* <div>
              <p> {item.PickupLocation}</p> 
              <p> {item.deliveryLocation}</p>
              </div>
              <div>
              {getLocationImage(item.orderStatus)}
             </div> */}
            </td>
            <td> 
              <h6>{item.createdAt.slice(0,10)}  </h6>
              <p>  <span> {item.createdAt.slice(15,19)}</span> <span> to </span> {item.updatedAt.slice(15,19)}</p> 
            </td>
            <td>{item.customerEmail}</td>
            <td>
              <h5 className={getStatusClass(item.orderStatus)}> </h5>
              <h5 className={getStatusClass(item.orderStatus)}> {item.orderStatus}</h5></td>
            <td style={{textAlign:"end" }} className="button-action">

            {getOpenbutton(item)}
             {getStatusButton(item)}
            
             </td>
         </tr>
          )
        })}</> 
      </tbody>
    </table>
  
    </Typography>
       <div className="Pagination-wrapper-Main-Whole"> 
        <Pagination count={13} page={page} onChange={handleChange} sx={{width:'30%'}}/>
      </div>
   </Stack>
    </>
  )
}

export default React.memo(OrdersTable)