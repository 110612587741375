import React, { useEffect, useState } from 'react'
import Header from './Header'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Maps from '../SubComponents/Maps';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OrdersTable from '../SubComponents/OrdersTable';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import { getFleetList, robotListfleetId } from '../ApI/Index';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FleetIdAction, RobotIdAction } from '../redux/actions';
import LeafletMap from '../SubComponents/LeafletMap';
import { Switch } from '@mui/material';



function Dashboard() {
  const [mapcheck, setmapcheck] =useState(false)
  const [robotList, setRobotList] =useState() 
  const [checkaval, setcheckaval]=useState(false)
  const userData= useSelector(state=>state.UserloginData)
 const useremail= localStorage.getItem('useremail')
 const token =localStorage.getItem('token')
 const isLogged= localStorage.getItem('Isloggedin')
 const navigate=useNavigate()
 const fleetid=''  
 const dispatch =useDispatch()
//  const fleetData=useSelector(state=>state.fleetdataReducer)
 const fleetData = useLocation()
 localStorage.setItem('fleetId',fleetData.state.name.fleetId)
 useEffect(()=>{
    if(isLogged==='LoginFalse'){
      window.location.replace('/')
    }
    else{
    //  localStorage.setItem('fleetID',fleetData?.fleetdata?.fleetId)
    //  console.log('fleetData',fleetData)
  
    setInterval(()=>{
      robotListfleetId(fleetData.state.name.fleetId , token).then((res)=>{
      setRobotList(res.data)
      // console.log('RobotStatusFleetId', res.data.data,res.data.data[0][0].robotId)
      dispatch(RobotIdAction(res.data.data))    
    })
    .catch((err)=>{
     console.log(err)
    })
  },8000) 
     dispatch(FleetIdAction(fleetData.fleetId))
    }
  },[fleetData])
useEffect(()=>{
  robotList?.data?.map((data)=>{
         
    //  console.log('datadatadatadatadatadata',data)
    if(data[0].robotStatus==="AVAILABLE"){
            setcheckaval(true)
          }
          else{
            setcheckaval(false)
          }
  })
})

   const robotBattery=(status)=>{
    switch(status){
      case "OK":
        return  <Battery80Icon className="rotate-90"/>  
      case "LOW":
        return <Battery1BarIcon className="rotate-90" style={{marginRight:'20px'}}/>   
      default:
       return  <Battery50Icon className="rotate-90"/>
        
      }
}
  return (
    <>
    <Header checkaval={checkaval}/>
    <div className='container Dashboard-WholeMain-wrapper'>
   
       <div className="row"> 
          <div className='col-md-4 text-center Dashboard-content-div-one-left-side border-blue-top'> 
             <h3 className='Dashboard-content-div-one-left-side-h3'>{fleetData.state.name.fleetName} </h3>
             {robotList?.data?.map((item)=>{
              return (
                <div className="d-flexx-center Dashboard-content-inner-wrapper-one"> 
                 <div className='Dashboard-content-div-one-left-side-inner-one'> 
                    <h5> {item[0].robotId}</h5>
                    {item[0].robotStatus==="AVAILABLE" ?  <p className="text-left m-0 p-color-green">Available</p>: <p className="text-left m-0 p-color-red"> UnAvailable</p> }
                </div>
               <div className='Dashboard-content-div-one-left-side-inner-two'> 
                  <MoreHorizIcon className="rotate-90" style={{color:'rgb(1 115 212)'}} />
                  <div> 
                    <SignalCellularAltIcon/>
                    {/* <Battery50Icon className="rotate-90"/> */}
                    {robotBattery(item[0].robotBatteryStatus)}
                    { parseInt(item[0].robotBattery).toFixed(0)} %                   
                    {item[0].robotStatus==="AVAILABLE" ? 
                       <PauseCircleOutlineOutlinedIcon className="play-icon p-color-green"/>
                   :   <PlayCircleFilledWhiteOutlinedIcon className="play-icon p-color-red"/>}
                  </div>
              </div> 
             </div>
              )
             })}

             {/* <div className="d-flexx-center Dashboard-content-inner-wrapper-one"> 
                <div className='Dashboard-content-div-one-left-side-inner-one'> 
                    <h5 > ROVERSIM2</h5>
                   
                </div>
               <div className='Dashboard-content-div-one-left-side-inner-two'> 
                  <MoreHorizIcon className="rotate-90" style={{color:'rgb(1 115 212)'}}/>
                  <div> 
                    <SignalCellularAltIcon/>
                    <Battery50Icon className="rotate-90"/>
                    78%
                   <PlayCircleFilledWhiteOutlinedIcon className="play-icon p-color-red"/>
               
                  </div>
              </div> 
             </div> */}
          </div>
           <div className='col-md-8 text-center dashboard-right-side-div'>
           <div className="Map-Main-Whole-wrapper border-blue-top">
             <div className='Map-Main-content-heading-switch'>
              <h3 className='Dashboard-content-div-one-left-side-h3'>Maps</h3>
              <FormGroup style={{display:'flex', flexDirection:'row'}}>
              <FormControlLabel control={<Switch checked={mapcheck} onChange={()=>{setmapcheck(!mapcheck)}} />} label="" />
                 <span className='Dashboard-content-div-one-left-side-h3 google-map-css'> Google Map</span>
             </FormGroup> 
            </div>
             {mapcheck ? <LeafletMap/> :<Maps fleetid={fleetData.state.name.fleetId}/> }
          </div>
                    </div>
        </div>
        <div className='row'> 
             <div className="DashboardPage-Orders-list-wrapper border-blue-top"> 
                 <div className="d-flexx-center w-full DashboardPage-Orders-list-wrapper-div-one"> 
                   <h3 className='Dashboard-content-div-one-left-side-h3'> Orders</h3>
                 </div>
                 <div>
                   <OrdersTable/>
                 </div>
             </div>
       </div>
    </div>
    </> 
  )
}

export default React.memo(Dashboard)