import React from 'react'
import "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { iconPerson } from './IconMarker';

function LeafletMap() {
    const positionarray=[
        [53.506,-0.09],
        [59,-1],
        [60, -20]
    ]
    const position = [51.505, -0.09]
    return (
    <div>
  <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{height:'300px'}}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
        <Marker position={position}
                icon={iconPerson} >
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
    {positionarray.map((item)=>{
     return <Marker position={item}
                    icon={iconPerson}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
       </Popup>
    </Marker>
    })}
   
  </MapContainer>

    </div>
  )
}

export default LeafletMap